@import 'variables.scss';

// CARBON IMPORTS
@import '@carbon/type/scss/font-face/sans';
@import '@carbon/type/scss/type';
@import '@carbon/type/scss/classes';
@import '@carbon/colors/scss/colors';
@import '@carbon/layout/scss/layout';
@import '@carbon/grid/scss/grid';

@include carbon--type-reset();
@include carbon--font-face-sans();

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  background-color: $white-0;
  line-height: 1.3em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  @include properties($body-long-02);
  color: #282828;
}

strong {
  font-weight: 600;
}

a {
  color: $blue-60;
  text-decoration: none;
}

em {
  display: inline-block;
  @include properties($label-01);
}

hr {
  border: 0;
  border-bottom: 1px solid rgb(205, 205, 205);
}

// Supporting styles
.code-01 {
  @include properties($code-01);
}

.code-02 {
  @include properties($code-02);
}

.label-01 {
  @include properties($label-01);
}

.helper-text-01 {
  @include properties($helper-text-01);
}

// Body
p {
  // for short paragraphs
  // with no more than four lines
  @include properties($body-short-02);
  margin-bottom: 0.7rem;
  text-align: left;
}

p.long {
  // for long paragraphs
  // with more than four lines
  @include properties($body-long-02);
}

p.body-short-01 {
  @include properties($body-short-01);
  margin-bottom: 0rem;
}

p.body-long-01 {
  @include properties($body-long-01);
}

// Headings
h1 {
  @include properties($productive-heading-07);
}

h2 {
  @include properties($productive-heading-06);
}

h3 {
  @include properties($productive-heading-05);
  margin-bottom: 1rem;
}

h4 {
  @include properties($productive-heading-04);
  margin-bottom: 1rem;
}

h5 {
  @include properties($productive-heading-03);
  margin-bottom: 1rem;
}

h6 {
  @include properties($productive-heading-02);
  margin-bottom: 1rem;
}

h6 ~ p.body-short-01,
h6 ~ p.body-short-02 {
  margin-top: -0.75rem;
}

// Layout
.bx--row.seamless {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.bx--fieldset {
  margin-bottom: 0;
}
