@import '@carbon/colors/scss/colors';
@import '@carbon/grid/scss/grid';

.Home {
  .bx--grid {
    padding: 0;
  }
  p {
    font-family: noort, serif;
    font-weight: 700;
    font-style: normal;
  }
  h1,
  h2,
  h3 {
    font-family: fatfrank, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: $white-0;
  }
  h1 {
    font-size: 4rem;
  }

  // COUNTDOWN
  .col-countdown-right {
    margin-top: 3rem;
    text-align: right;
    @media only screen and (max-width: 1054px) {
      text-align: left;
      margin-top: 0;
      margin-bottom: 3rem;
    }

    h2 {
      font-size: 1.6rem;
      margin-bottom: 1rem;
      margin-right: 1rem;
      @media only screen and (max-width: 1054px) {
        font-size: 1.4rem;
        margin-bottom: 0rem;
      }
    }
    #countdown {
      margin: 0 auto 0 auto;

      span {
        border: 5px solid $orange-40;
        margin-right: 1rem;
        padding: 1rem;
        display: inline-block;
        text-align: center;
        width: 6rem;
        @media only screen and (max-width: 1130px) {
          border: 0;
          margin-right: 0rem;
          padding: 0;
          width: 5rem;
          font-weight: normal;
          font-size: 1rem;
        }
      }
      strong {
        display: block;
        font-size: 2rem;
        @media only screen and (max-width: 1130px) {
          font-size: 3rem;
          margin-bottom: -0.5rem;
        }
        color: $orange-40;
      }
    }
  }

  // TOP HEADER ROW
  .top-header {
    text-align: left;
    padding: 0 2rem;
    @media only screen and (max-width: 600px) {
      padding: 0 1.5rem;
    }
    h1.logo {
      color: #ff0000;
      line-height: auto;
      margin-bottom: 1rem;
      font-size: 6rem;
      @media only screen and (max-width: 600px) {
        font-size: 5.3rem;
        line-height: 5rem;
      }
    }

    h2 {
      color: $gray-90;
    }
    .App-header {
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: left;
      color: white;
      padding: 3rem 0;
    }
  }
  .slider-row {
    padding: 0 2rem;
    background-color: #fe8020;
    position: relative;
    padding-top: 2rem;
    @media only screen and (max-width: 600px) {
      padding: 0 1.5rem;
      padding-top: 1.5rem;
    }
  }
  .slider-hold {
    position: relative;
    padding-left: 2rem;
    margin-top: 0.5rem;
    @media only screen and (max-width: 600px) {
      padding-left: 1rem;
      margin-top: 1.25rem;
    }
  }

  .edition {
    position: absolute;
    left: 2.5rem;
    bottom: 20px;
    color: #fff;
    display: block;
    z-index: 99999;
    color: #000;
    @media only screen and (max-width: 600px) {
      left: 1.8rem;
      bottom: 23px;
    }
    &.first {
      left: 1.5rem;
      @media only screen and (max-width: 600px) {
        left: 1rem;
      }
    }
    em {
      font-size: 0.9rem;
    }
  }
  .slider-bg {
    display: none;
    position: absolute;
    width: 100%;
    z-index: 9999;
    margin-left: -1rem;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .slider-bg-mobile {
    display: none;
    width: 100%;
    margin-bottom: 0.5rem;
    margin-top: -1rem;
    @media only screen and (min-width: 600px) {
      display: none;
    }
  }

  .bottomSlides {
    // background-color: #d38333;
    height: 6rem;
    width: 97%;
    p {
      font-size: 2rem;
      padding-top: 0.9rem;
      padding-left: 1.5rem;
      color: #000;
      @media only screen and (max-width: 600px) {
        padding-left: 1rem;
        font-size: 1.9rem;
        padding-top: 0.8rem;
      }
    }
  }
  .slick-slider {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: 2rem;

    .slick-next,
    .slick-prev {
      bottom: 49px;
      top: auto;
      z-index: 999;
      @media only screen and (max-width: 600px) {
        bottom: 50px;
      }
    }
    .slick-next {
      right: 3.4rem;
      left: auto;
      @media only screen and (max-width: 600px) {
        right: 1.5rem;
      }
    }
    .slick-prev {
      right: 6.5rem;
      left: auto;
      @media only screen and (max-width: 600px) {
        right: 4.1rem;
      }
    }
    .slick-next:before,
    .slick-prev:before {
      color: #fff;
      font-size: 50px;
      @media only screen and (max-width: 600px) {
        font-size: 40px;
      }
    }
    .slick-list {
      text-align: center;

      img {
        // max-width: 500px;
        width: 100%;
      }
    }
  }
  .slick-slide {
    img {
      width: 92% !important;
      margin-top: 0.5rem;
      margin-left: 1rem;
      margin-bottom: 1rem;
      border: 7px solid #d38333;
      @media only screen and (max-width: 600px) {
        width: 92% !important;
        padding: 0;
        margin: 0;
        border: 7px solid #d38333;
        margin: 0 1rem;
      }
    }
  }
  .slider-text {
    margin: 0;
    text-align: left;
    overflow: hidden;
    position: relative;
    a {
      text-decoration: underline;
      color: #fff;
    }
    a:hover {
      color: $gray-90;
    }
    img {
      opacity: 0.3;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 160px;
      @media only screen and (max-width: 600px) {
        width: auto;
      }
    }
    .text-wrapper {
      margin: 0.5rem 0 4rem;
      h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
        margin-left: 1.5rem;
        font-size: 2.5rem;
        @media only screen and (max-width: 600px) {
          margin-top: 1rem;
        }
      }
      @media only screen and (max-width: 600px) {
        h2 {
          margin-left: 1rem;
          font-size: 2.5rem;
          margin-top: -1rem;
        }
      }
      position: relative;
    }
    p {
      padding: 0.5rem 0;

      color: #fff;
      font-size: 1.5rem;
      padding-right: 4.5rem;
      padding-left: 1.5rem;
      @media only screen and (max-width: 600px) {
        padding: 0.5rem 1rem;
      }
      span {
        background-color: #fe8020;
        span {
          white-space: pre-wrap;
        }
      }
    }
  }
  // TOP CONTENT ROW
  .top-row {
    display: none;
    background-color: $orange-40;
    color: $white-0;
    @media only screen and (max-width: 785px) {
      margin-top: 8rem;
    }
    @media only screen and (max-width: 423px) {
      margin-top: 10rem;
    }
    .top-text-left {
      margin: 3rem 0 4rem;
      text-align: left;

      p {
        font-size: 1.5rem;
        padding-right: 4.5rem;
        @media only screen and (max-width: 600px) {
          padding: 0 1.5rem;
        }
      }
    }

    .slick-slider {
      width: 500px;
      margin: 3rem auto 4rem auto;
      .slick-list {
        text-align: center;
        img {
          max-width: 500px;
        }
      }
    }
  }
}

// ROADMAP ROW
.row-roadmap {
  // background-color: $green-30;
  padding: 4rem 2rem;
  text-align: center;
  h1 {
    color: $gray-90;
  }
  @media only screen and (max-width: 600px) {
    padding: 4rem 1rem;
  }
}

// MEET TEAM ROW
.row-meet-team {
  background-color: $yellow-30;
  padding: 4rem 0;
  text-align: center;
  @media only screen and (max-width: 600px) {
    padding: 4rem 1rem;
  }
}
.row-faqs {
  // background-color: $orange-30;
  padding: 4rem 2rem;
  text-align: center;
  @media only screen and (max-width: 600px) {
    padding: 4rem 1rem;
  }
  h1 {
    color: $gray-90;
  }
  .bx--accordion {
    margin-top: 2rem;
    .bx--accordion__item {
      .bx--accordion__title {
        font-family: fatfrank, sans-serif;
        font-size: 2rem;
      }
      .bx--accordion__content p {
        font-size: 1.5rem;
      }
    }
  }
}

// BOTTOM FOOTER ROW
.bottom-footer {
  background-color: #ff0000;
  .App-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    color: $white-0;
  }
}
